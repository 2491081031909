import Paragraphe from "../../components/text/Paragraphe";
import StyledCard from "./StyledCard";
import CardButton from "./CardButton";
import BornePropertiesList from "./BornePropertiesList";


interface IBorneCardProps {
  image: string;
  name: string;
  borneType: string;
  description: string;
  properties: { key: string, label: string, description?: string, value?: string }[];
  price?: string;
  selected: boolean;
  onClick: () => void;
  ribbon?: string;
}

const BorneCard = ({ image, name, borneType, description, properties, price, selected, onClick, ribbon }: IBorneCardProps) => {
  return (
    <StyledCard
      selected={selected}
      ribbon={ribbon}
    >
      <div style={{display: "flex", alignItems: "center", gap: "15px", marginBottom: "15px"}}>
        <img src={image} style={{ width: "120px", height: "120px", objectFit: "contain", float: "left" }}/>
        <div>
          <div style={{fontSize: "18px", fontWeight: "600"}}>{borneType}</div>
          <div style={{fontSize: "18px"}}>{name}</div>
        </div>
      </div>
      {/*<Paragraphe style={{ marginBottom: "15px" }}>{description.split('\n').map(x => <>{x}<br/></>)}</Paragraphe>*/}
      <BornePropertiesList properties={properties} />
      <div style={{ flex: 1 }}></div>
      { price != null && <div style={{ fontSize: "20px", fontWeight: "800", marginTop: "10px", marginBottom: "5px" }}>À partir de {price}</div> }
      <CardButton selected={selected} onClick={onClick}>Choisir</CardButton>
    </StyledCard>
  );
}

export default BorneCard;
