import StickyFooter from "../components/StickyFooter";
import H1 from "../components/text/H1";
import FancyButton from "../components/FancyButton";
import { Spin } from "antd";
import { useEffect } from "react";
import BornePropertiesList from "./cards/BornePropertiesList";

const SyntheseStepView = (props: {
  borneTitle: string,
  borneType: string,
  borneDescription: string,
  borneProperties: { key: string, label: string, description?: string, value?: string }[],
  borneImage: string,
  arePricesLoading: boolean,
  onShow: () => void,
} & (
  { arePricesLoading: true } | {
    totalAfterTaxDeduction: string,
    savingsAfter5Years: string | null,
    depositPercentage: string,
    depositAmount: string,
    vatRate: string,
    isPayButtonLoading: boolean,
    isQuoteButtonLoading: boolean,
    onClickOnPayButton: () => void,
    onClickOnQuoteButton: () => void,
  }
)) => {
  
  useEffect(() => { props.onShow(); }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px", padding: "0 10px", color: "#293276", boxSizing: "border-box" }}>
      <H1 style={{ margin: "0", marginTop: "10px" }}>Votre offre d'installation clé en main</H1>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px", padding: "25px", border: "2px solid #293276", borderRadius: "25px", boxSizing: "border-box" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ }}><img src={props.borneImage} style={{ width: "150px", height: "150px", objectFit: "contain" }} /></div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
            <div style={{ fontSize: "18px", fontWeight: 600 }}>{props.borneType}</div>
            <div style={{ fontSize: "16px" }}>{props.borneTitle}</div>
          </div>
        </div>
        <div>
          <div style={{ fontWeight: 700, fontSize: "16px" }}>Forfait incluant :</div>
          <BornePropertiesList properties={[
            { label: '+ Interrupteur et coffret électrique ', key: 'interrupteurEtCoffretElectrique' },
            { label: '+ Disjoncteur différentiel', key: 'disjoncteurDifferentiel' },
            { label: '+ Cheminement de câble et gaine', key: 'cheminementDeCableEtGaine' },
            { label: '+ Installation et mise en service', key: 'installationEtMiseEnService' },
            ...props.borneProperties.filter(x => x.key === 'garantie').map(x => ({ label: `+ ${x.label} ${x.value}`, key: x.key })),
            { label: '+ 2 badges RFID offert', key: 'deuxBadgesRfidOffert' },
          ]} />
        </div>
        {
          props.arePricesLoading ? <Spin /> : (<>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px", color: "#293276", alignSelf: "center", fontSize: "0.8em", fontWeight: 600 }}>
              <img src="/totalAfterTaxDeduction.svg" width="64" height="64" />
              <div>Tarif après abattement d'impôt</div>
              <div style={{ fontSize: "4em", lineHeight: "1em" }}>{props.totalAfterTaxDeduction}</div>
              <div>seulement</div>
            </div>
            <FancyButton
              loading={props.isPayButtonLoading}
              onClick={props.onClickOnPayButton}
              style={{ boxSizing: 'border-box' }}
              >Commander et planifier</FancyButton>
            <FancyButton
              style={{ background: "#293276", boxSizing: 'border-box' }}
              loading={props.isQuoteButtonLoading}
              onClick={props.onClickOnQuoteButton}
            >Devis détaillé</FancyButton>
            {
              /*props.savingsAfter5Years != null && (
                <div style={{ display: "flex", alignItems: "center", gap: "15px", justifyContent: "space-between" }}>
                  <img src="/tirelire.svg" width="40" height="40" />
                  <div style={{ fontSize: "0.8em" }}>Votre borne vous permettra d'économiser sur&nbsp;5&nbsp;ans</div>
                  <div style={{ whiteSpace: "nowrap", fontWeight: 600, fontSize: "1.5em" }}>{props.savingsAfter5Years}</div>
                </div>
              )*/
            }
            <div style={{ display: "flex", alignItems: "center", gap: "15px", justifyContent: "space-between" }}>
              <div>
                <div style={{ fontWeight: 700, fontSize: "16px", lineHeight: "1.2em" }}>Acompte de {props.depositPercentage} à régler</div>
                <div style={{ color: "#666", fontSize: "12px" }}>TTC ({props.vatRate})</div>
              </div>
              <div style={{ whiteSpace: "nowrap", fontWeight: 600, fontSize: "1.5em" }}>{props.depositAmount}</div>
            </div>
          </>)
        }
      </div>
      {
        props.arePricesLoading ? <></> : (<>
          {/* TODO : modifier footer ici */}
          <StickyFooter>
          </StickyFooter>
        </>)
      }
    </div>
  );

};

export default SyntheseStepView;
