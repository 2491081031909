import { Badge, Card } from "antd";
import Ribbon from "antd/es/badge/Ribbon";
import { styled } from "styled-components";

const PreStyledRibbon = (props: any) => {
  return <Ribbon {...props} />;
};

const StyledRibbon = styled(Badge.Ribbon)`
  background: linear-gradient(90deg,#00bafd 0%,#00fe6e 100%) !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  top: -8px;
  ${props => props.color != "transparent" ? "padding: 5px 8px;" : ""}
  //white-space: unset;
`;

const StyledCard = ({selected, ribbon, ...otherProps}: any) => {
  return (
    <div style={{flex: 1}}>
      <StyledRibbon text={ribbon} color="transparent">
        <Card
          {...otherProps}
          style={{ border: selected ? "2px solid #293276" : "2px solid #BEC0CB", borderRadius: "25px", color: "#293276", width: "100%", height: "100%",...otherProps.style }}
          bodyStyle={{ display: "flex", flexDirection: "column", height: "100%" }}
        />
      </StyledRibbon>
    </div>
  );
};



export default StyledCard;